@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-main;
    font-family: "Poppins", sans-serif;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #98752D 0%, #E3C246 50%, #98752D 100%);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #1b2533;
}

@layer components {
  .dropdown {
    @apply w-full lg:max-w-[296px] cursor-pointer;
  }
  .dropdown-btn {
    @apply flex h-[64px] items-center px-[18px] border rounded-lg;
  }
  .dropdown-icon-primary {
    @apply text-2xl mr-[18px] text-blue-800;
  }
  .dropdown-icon-secondary {
    @apply text-2xl text-blue-800 ml-auto;
  }
  .dropdown-menu {
    @apply mt-2 px-6 py-8 text-[15px] space-y-6 shadow-lg bg-white absolute w-full z-10 list-none rounded-md p-4; 
  }
  .carousel {
    position: relative;
  }
  .list-disc-edit {
    @apply list-disc
  }

  .list-disc-edit li::marker {
    font-size: 9px;
  }

  .carousel img {
    width: 100%;
    height: auto;
  }

  .carousel button {
    color: #fff;
    font-size: 2rem;
  }
}
